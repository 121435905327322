import { ROUTES } from '../routes';

import { config } from '@/config';

export const commonRoutes = [
  /^\/2fa\.*/,
  /^\/recover-passcode\/(confirm|create)/,
  /^\/support\.*/,
];

export const authRoutes = [
  ...commonRoutes,
  /^\/join\.*/,
  /^\/signin\.*/,
  /^\/recover-passcode\.*/,
] as Array<string | RegExp>;

export const publicRoutes = [
  ...authRoutes,
  /^\/.well-known\.*/,
  /^\/signup\.*/,
  /^\/v2\/signup\.*/,
  /^\/goodbye\.*/,
  /^\/api\/v1\.*/,
  // Add other public routes here
  ROUTES.TERMS_OF_USE.INDEX,
  ROUTES.PRIVACY_POLICY.INDEX,
] as Array<string | RegExp>;

export const restrictedRoutes = [
  ...publicRoutes,
  /^\/address-information/,
  /^\/personal-information/,
  /^\/kyc\.*/,
  /^\/v2\/address-information/,
  /^\/v2\/personal-information/,
  /^\/v2\/kyc\.*/,
];

export function isRouteMatch(
  pathname: string,
  routes: Array<string | RegExp>,
): boolean {
  return routes.some((route) =>
    typeof route === 'string' ? route === pathname : route.test(pathname),
  );
}

export const getOnboardingRoutes = () => {
  const { ONBOARDING_VERSION } = config ?? {};
  return ONBOARDING_VERSION === 'v2' ? ROUTES.ONBOARDING_V2 : ROUTES.ONBOARDING;
};
