import { createComponent } from '@/ui/system';
import { BaseButton, BaseButtonProps } from '../base-button';
import { cn } from '@/lib/utils';

export type NavlinkProps = BaseButtonProps;

export const Navlink = createComponent<NavlinkProps>(
  ({ layout = 'default', block, ...props }, ref) => {
    return (
      <BaseButton
        {...{
          ...props,
          ref,
          role: 'link',
          className: {
            ...props.className,
            root: cn('no-underline ', { 'w-full': block }, props.className),
          },
          layout,
        }}
      />
    );
  },
);
